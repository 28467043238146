import { Contentful_HeroBlock, Maybe } from '../../types/gatsby-types'
import { ImageTransformer, LinkTextTransformer, RichTextTransformer } from '../models'
import { HeroProps } from '../../components/Hero'

export default (props: Maybe<Contentful_HeroBlock>, t: any, extraParams: any): HeroProps => ({
  title: props?.title as string,
  text: RichTextTransformer(props?.text?.json),
  image: ImageTransformer(props?.image ?? null),
  backgroundColor: extraParams?.pageCommons?.color,
  cta: props?.cta ? LinkTextTransformer(props?.cta) : undefined,
  backgroundImage: { alt: 'LiberteClasse - Background - Hero', src: '/images/common/cloud.png' },
})
