import { Contentful_TextBlock, Maybe } from '../../types/gatsby-types'
import { RichTextTransformer } from '../models'
import { TextBlockProps } from '../../components/TextBlock'

export default (
  props: Maybe<Contentful_TextBlock>
  // t: any,
  // extraParams: any
): TextBlockProps => {
  return {
    text: RichTextTransformer(props?.text?.json),
  }
}
