import { Contentful_BreadcrumbBlock, Contentful_Page, Maybe } from '../../types/gatsby-types'
import { BreadcrumbProps } from '../../components/Breadcrumb'

export default (
  props: Maybe<Contentful_BreadcrumbBlock>,
  t: any,
  extraParams: any
): BreadcrumbProps => ({
  label: props?.label ?? '',
  parents: props?.parentsCollection?.items?.map((page: Maybe<Contentful_Page>) => ({
    text: page?.title ?? '',
    link: ('/' + page?.slug).replace(/\/+/, '/'),
  })),
})
