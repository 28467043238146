import React, { FC, ReactNode } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'
import VideoContent, { VideoContentProps } from '../VideoContent'
import { ImageGatsbyProps } from '../ImageGatsby'

import * as SC from './styled'

export type FeatureBlockProps = {
  className?: string
  title: string
  text: ReactNode
  cta?: LinkProps
  video?: VideoContentProps
  image: ImageGatsbyProps
  isInverted?: boolean
  isInvertedMobile?: boolean
}

const FeatureBlock: FC<FeatureBlockProps> = (props) => {
  const { className, title, text, cta, image, isInverted, isInvertedMobile, video } = props
  return (
    <SC.Box className={className}>
      <SC.Wrapper
        maxWidth="xl"
        disableGutters
        $isInverted={isInverted}
        $isInvertedMobile={isInvertedMobile}
      >
        <SC.BoxImage>
          {video ? <VideoContent {...video} /> : <SC.Img {...image} objectFit={'contain'} />}
        </SC.BoxImage>
        <SC.Content>
          <SC.Intro>
            <SC.Title>{title}</SC.Title>
            <SC.Text>{text}</SC.Text>
          </SC.Intro>
          {cta && (
            <SC.Cta {...cta} icon={Icons.longArrowRight} iconColor={light.colors.freeSpeechBlue} />
          )}
        </SC.Content>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default FeatureBlock
