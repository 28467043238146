import styled from 'styled-components'
import { Container, TextField } from '@material-ui/core'

export const Box = styled.div``

export const Wrapper = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: left;
    align-items: flex-start;
  }
`

export const Field = styled(TextField)`
  margin-bottom: 1rem;
  & .MuiOutlinedInput-input {
    font-size: 1.6rem;
  }
  & .MuiInputBase-root {
    background-color: ${(props) => props.theme.palette.colors.pureWhite};
  }
`
