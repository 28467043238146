import React from 'react'
import { graphql } from 'gatsby'

import VideoContent from '../../components/VideoContent'

const VideoBlock = (props: any) => {
  console.log('video', props)
  return <VideoContent {...props} />
}

export default VideoBlock

export const query = graphql`
  fragment VideoBlock on Contentful_VideoBlock {
    __typename
    video {
      title
      description
      url
      contentType
    }
    youtube
    cover {
      url(transform: { width: 1440 })
      localAsset {
        childImageSharp {
          gatsbyImageData(width: 1440)
        }
      }
    }
  }
`
