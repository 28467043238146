import React, { FC } from 'react'

import * as SC from './styled'

export type FieldLabelProps = {
  className?: string
  label: string
  required?: boolean
  hasError?: boolean
}

const FieldLabel: FC<FieldLabelProps> = (props) => {
  const { className, label, required, hasError } = props

  return <SC.FieldLabel className={className}>{`${label} ${required && '*'}`}</SC.FieldLabel>
}

export default FieldLabel
