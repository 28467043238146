import React, { FC, ReactNode } from 'react'

import * as SC from './styled'

export type TextBlockProps = {
  className?: string
  text: ReactNode
}

const TextBlock: FC<TextBlockProps> = (props) => {
  const { className, text } = props
  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Text>{text}</SC.Text>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default TextBlock
