import styled from 'styled-components'
import { Container } from '@material-ui/core'

import LinkButton from '../LinkButton'
import Image from '../Image'

export const Box = styled.div`
  position: relative;
`

export const BoxBackgroundImage = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export const BackgroundImage = styled(Image)`
  width: 60rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
  }
`

export const Wrapper = styled(Container)`
  max-width: 100%;
  background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom-left-radius: 100% 15%;
  border-bottom-right-radius: 100% 15%;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
    border-bottom-left-radius: 100% 25%;
    border-bottom-right-radius: 100% 25%;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.pureWhite};
  font-size: 3.2rem;
  text-transform: none;
  max-width: 27rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    margin: 0;
    max-width: 50rem;
  }
`

export const CtaButton = styled(LinkButton)`
  margin-bottom: 4rem;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  background-color: ${(props) => props.theme.palette.colors.neonCarrot};
  & > span {
    ${(props) => ({ ...props.theme.typography.h6 })}
    color: ${(props) => props.theme.palette.colors.pureWhite};
    line-height: 2.25;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.kournikova};
    border-color: ${(props) => props.theme.palette.colors.kournikova};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 4rem;
  }
`
