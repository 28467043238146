import React, { FC, ReactNode } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'
import { ImageProps } from '../Image'
import VideoContent, { VideoContentProps } from '../VideoContent'

import * as SC from './styled'

export type CheckmarkBlockProps = {
  className?: string
  title: string
  text: string | ReactNode
  checkmarks?: string[]
  cta?: LinkProps
  image: ImageProps
  video?: VideoContentProps
}

const CheckmarkBlock: FC<CheckmarkBlockProps> = (props) => {
  const { className, title, text, checkmarks, cta, image, video } = props
  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Content>
          {video ? (
            <>
              <SC.ColumnSet>
                <SC.LeftColumn>
                  <SC.Title>{title}</SC.Title>
                  <SC.Text>{text}</SC.Text>
                </SC.LeftColumn>
                {video && (
                  <SC.RightColumn>
                    <SC.Video>
                      <VideoContent {...video} isWithoutPadding />
                    </SC.Video>
                  </SC.RightColumn>
                )}
              </SC.ColumnSet>
              <SC.ColumnSet>
                <SC.LeftColumn>
                  <SC.Checkmarks hasVideo={!!video}>
                    {checkmarks?.map((checkmark, index) => (
                      <SC.Checkmark key={index}>
                        <SC.CheckmarkIcon icon={Icons.check} />
                        <SC.CheckmarkText>{checkmark}</SC.CheckmarkText>
                      </SC.Checkmark>
                    ))}
                  </SC.Checkmarks>
                  {cta && (
                    <SC.Cta
                      {...cta}
                      icon={Icons.longArrowRight}
                      iconColor={light.colors.freeSpeechBlue}
                    />
                  )}
                </SC.LeftColumn>
                <SC.RightColumn>
                  <SC.StyledImage {...image} />
                </SC.RightColumn>
              </SC.ColumnSet>
            </>
          ) : (
            <SC.ColumnSet>
              <SC.LeftColumn>
                <SC.Title>{title}</SC.Title>
                <SC.Text>{text}</SC.Text>
                <SC.Checkmarks>
                  {checkmarks?.map((checkmark, index) => (
                    <SC.Checkmark key={index}>
                      <SC.CheckmarkIcon icon={Icons.check} />
                      <SC.CheckmarkText>{checkmark}</SC.CheckmarkText>
                    </SC.Checkmark>
                  ))}
                </SC.Checkmarks>
                {cta && (
                  <SC.Cta
                    {...cta}
                    icon={Icons.longArrowRight}
                    iconColor={light.colors.freeSpeechBlue}
                  />
                )}
              </SC.LeftColumn>
              <SC.RightColumn>
                <SC.StyledImage {...image} />
              </SC.RightColumn>
            </SC.ColumnSet>
          )}
        </SC.Content>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default CheckmarkBlock
