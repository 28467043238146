import React, { FC, ReactNode } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type QuestionProps = {
  question: string
  link: string
}

export type CategoryProps = {
  icon: Icons
  color?: string
  title?: string
  text?: ReactNode | string
  questions?: QuestionProps[]
  cta?: LinkProps
}

export type HelpBlockProps = {
  className?: string
  categories?: CategoryProps[]
}

const HelpBlock: FC<HelpBlockProps> = (props) => {
  const { className, categories } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Categories>
          {categories?.map((category, index) => (
            <SC.Category key={index}>
              <SC.CategoryIcon icon={category.icon} color={category?.color} />
              <SC.CategoryTitle>{category.title}</SC.CategoryTitle>
              <SC.CategoryText>{category.text}</SC.CategoryText>
              <SC.CategoryBoxQuestions>
                {category?.questions?.map((question, index) => (
                  <SC.CategoryQuestions key={index}>
                    <SC.CategoryQuestion href={question.link}>
                      {question.question}
                    </SC.CategoryQuestion>
                  </SC.CategoryQuestions>
                ))}
              </SC.CategoryBoxQuestions>
              {category.cta && (
                <SC.CategoryCta
                  {...category.cta}
                  icon={Icons.longArrowRight}
                  iconColor={light.colors.freeSpeechBlue}
                />
              )}
            </SC.Category>
          ))}
        </SC.Categories>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default HelpBlock
