import React, { FC } from 'react'

import { LinkProps } from '../Link'
import { ImageGatsbyProps } from '../ImageGatsby'

import * as SC from './styled'

export type CloudBlockProps = {
  className?: string
  title: string
  cta: LinkProps
  backgroundImage: ImageGatsbyProps
}

const CloudBlock: FC<CloudBlockProps> = (props) => {
  const { className, title, cta, backgroundImage } = props

  return (
    <SC.Box className={className}>
      {backgroundImage && (
        <SC.BoxBackgroundImage>
          <SC.BackgroundImage {...backgroundImage} />
        </SC.BoxBackgroundImage>
      )}
      <SC.Wrapper disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.CtaButton {...cta} />
      </SC.Wrapper>
    </SC.Box>
  )
}

export default CloudBlock
