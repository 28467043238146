import React, { FC, ReactNode, useMemo } from 'react'
import Youtube from 'react-youtube'

import * as SC from './styled'
import { VideoYoutube } from './styled'

export type VideoContentProps = {
  className?: string
  poster?: string
  youtube?: string
  sources?: {
    src: string
    type?: string
  }[]
  tracks?: {
    src: string
    srcLang?: string
    kind?: 'subtitles' | 'captions' | 'descriptions' | 'chapters' | 'metadata'
    label?: string
  }[]
  options?: { [key: string]: string }[]
  credit?: string | ReactNode
  text?: string | ReactNode
  isBig?: boolean
  isWithoutPadding?: boolean
  isInline?: boolean
}

const VideoContent: FC<VideoContentProps> = (props) => {
  const {
    className,
    youtube,
    poster,
    sources,
    tracks,
    options,
    text,
    credit,
    isBig,
    isWithoutPadding,
    isInline,
  } = props

  const renderSources = useMemo(
    () =>
      sources?.map((source, index) => <source {...source} key={`questionpushvideo-${index}`} />),
    [sources]
  )

  const renderTracks = useMemo(
    () =>
      tracks?.map((track, index) => <track {...track} key={`questionpushvideo-track-${index}`} />),
    [tracks]
  )

  return (
    <SC.VideoContent className={className} isWithoutPadding={isWithoutPadding} isInline={isInline}>
      <SC.Figure>
        {youtube ? (
          <SC.VideoYoutube>
            <Youtube videoId={youtube} />
          </SC.VideoYoutube>
        ) : (
          <SC.Video poster={poster ?? ''} controls {...options}>
            {renderSources}
            {renderTracks}
          </SC.Video>
        )}
        {(text || credit) && (
          <SC.Caption>
            {credit && <SC.Credit isBig={isBig}>{credit}</SC.Credit>}
            {text && <SC.Text isBig={isBig}>{text}</SC.Text>}
          </SC.Caption>
        )}
      </SC.Figure>
    </SC.VideoContent>
  )
}

export default VideoContent
