import { Contentful_QuestionBlock, Maybe } from '../../types/gatsby-types'
import { QuestionBlockProps } from '../../components/QuestionBlock'
import { LinkTextTransformer } from '../models'

export default (
  props: Maybe<Contentful_QuestionBlock> & { questionBlock: string }
  // t: any,
  // extraParams: any
): QuestionBlockProps => {
  return {
    title: props?.title as string,
    text: props?.questionBlock as string,
    cta: props?.cta ? LinkTextTransformer(props?.cta) : { text: '' },
  }
}
