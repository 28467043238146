import {
  Contentful_FaqRelatedBlock,
  Contentful_FaqSectionBlock,
  Maybe,
} from '../../types/gatsby-types'
import { faqIconTransform, getFaqSectionPageLink } from '../FaqSection/transform'
import { SeeAlsoProps } from '../../components/SeeAlsoBlock'

export default (
  props: Maybe<Contentful_FaqRelatedBlock>,
  t: any,
  extraParams: any
): SeeAlsoProps => ({
  title: props?.title ?? '',
  cards: props?.sectionsCollection?.items?.map((section: Maybe<Contentful_FaqSectionBlock>) => ({
    title: section?.title ?? '',
    icon: faqIconTransform(section?.icon),
    color: section?.color ?? '',
    cta: {
      text: t('faq_see_all', { count: section?.questionsCollection?.total }),
      link: getFaqSectionPageLink(section),
      target: '_self',
    },
  })),
})
