import styled from 'styled-components'
import { Container } from '@material-ui/core'

import LinkButton from '../LinkButton'
import Image from '../Image'

export const Hero = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  overflow: hidden;
`

export const Wrapper = styled(Container)`
  padding: 1rem 1rem 0 1rem;
  z-index: 5;
  position: relative;
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: flex;
    flex-direction: row;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    padding: 10rem 13rem 13rem 13rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  max-width: 40rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-right: 11rem;
    justify-content: center;
    flex-shrink: 0;
  }
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    line-height: 1.5;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Cta = styled(LinkButton)`
  margin: 1rem 0;
  background-color: ${(props) => props.theme.palette.colors.neonCarrot};
  max-width: max-content;
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.kournikova};
  }
  & > span {
    ${(props) => ({ ...props.theme.typography.h5 })}
  }
`

export const BoxHeroImage = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  ${(props) => props.theme.breakpoints.up('md')} {
    position: relative;
    height: 100%;
    align-self: end;
  }
`

export const HeroImage = styled(Image)`
  height: auto;
  width: 32rem;
  z-index: 10;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: auto;
    width: 100%;
    max-height: 50rem;
    max-width: 64rem;
  }
`

export const BoxBackgroundImage = styled.div<{ isFAQ?: boolean }>`
  position: absolute;
  bottom: -5%;
  width: 100%;
  left: ${(props) => (props.isFAQ ? '-280%' : '-50%')};
  ${(props) => props.theme.breakpoints.up('md')} {
    left: ${(props) => (props.isFAQ ? '-40%' : '0%')};
  }
`

export const BackgroundImage = styled.img``
