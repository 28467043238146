/* eslint-disable react/display-name */
import React, { FC, useMemo } from 'react'

import * as SC from './styled'

type ContentfulDebugBarProps = {
  typename: string
  datas?: any
  className?: string
  debugMode?: boolean
  linkSpace: string
}

const ContentfulDebugBar: FC<ContentfulDebugBarProps> = ({
  typename,
  linkSpace,
  datas = {},
  debugMode = false,
  className = '',
}) => {
  const propsEdit = useMemo(
    () => ({
      link: `${linkSpace}/${datas?.sys?.id}`,
      target: '_blank',
    }),
    [typename, datas]
  )
  if (!debugMode || !datas || !datas.sys || !datas.sys.id) {
    return null
  }

  return (
    <SC.Container className={className}>
      <SC.LinkItem>
        <SC.LinkInner {...propsEdit} />
      </SC.LinkItem>
      <SC.LinkItem>
        <SC.LinkInner
          {...propsEdit}
          text={`Content type : ${typename.replace('Contentful_', '')}`}
        />
      </SC.LinkItem>
    </SC.Container>
  )
}

export default ContentfulDebugBar
