import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import Image from '../Image'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    padding: 5rem;
  }
`

export const Content = styled.div`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
  }
`

export const ColumnSet = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`

export const LeftColumn = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 44rem;
    margin-right: 11rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  font-size: 2.8rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 3.6rem;
    margin: 0;
    padding: 0 0 0 2rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0 0 0 2rem;
    margin-bottom: 0;
  }
`

export const Checkmarks = styled.div<{ hasVideo?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    margin-top: ${(props) => (props.hasVideo ? '2rem' : '0')};
    padding-top: 0;
  }
`

export const Checkmark = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
  }
`

export const CheckmarkIcon = styled(Icon)`
  height: 6rem;
  width: 6rem;
  margin-right: 2rem;
  min-width: 6rem;
`

export const CheckmarkText = styled.h5`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
  }
`

export const Cta = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 1rem 1.6rem;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  max-width: max-content;
  border: none;
  ${(props) => ({ ...props.theme.typography.h5 })};
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 3rem;
  }
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
  }
`

export const Video = styled.div`
  width: 100%;
  max-width: 64rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    & > div {
      padding: 0 1rem 0 1rem;
    }
  }
`

export const StyledImage = styled(Image)`
  z-index: 10;
  width: 100%;
  height: auto;
  max-width: 64rem;
  margin: 2rem 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin: 0;
  }
`
