import styled from 'styled-components'

import IconC from '../Icon'

export const Container = styled.div`
  position: relative;
`
export const Icon = styled(IconC)`
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
`
export const Pebble = styled(IconC)``
