import styled from 'styled-components'
import { Container, TextField } from '@material-ui/core'

import FormSubmit from '../FormSubmit'

export const Box = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 2rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    max-width: 64rem;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 64rem;
  }
`

export const Form = styled.form`
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 64rem;
  }
`

export const Fields = styled.div`
  width: 100%;
`

export const Field = styled(TextField)`
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
`

export const MandatoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    text-align: start;
  }
`

export const SubmitButton = styled(FormSubmit)`
  color: ${(props) => props.theme.palette.colors.pureWhite};
  border-color: ${(props) => props.theme.palette.colors.neonCarrot};
  background-color: ${(props) => props.theme.palette.colors.neonCarrot};
  & > span {
    ${(props) => ({ ...props.theme.typography.h6 })}
    line-height: 2.25;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.kournikova};
    border-color: ${(props) => props.theme.palette.colors.kournikova};
    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
`
