import {
  Contentful_ConceptBlock,
  Contentful_ConceptItem,
  Contentful_ContactBlock,
  Maybe,
} from '../../types/gatsby-types'
import { Concept, ConceptBlockProps } from '../../components/ConceptBlock'
import { Icons } from '../../components/Icon/types'
import { ContactBlockProps } from '../../components/ContactBlock'
import { RichTextTransformer } from '../models'

export default (
  props: Maybe<Contentful_ContactBlock>
  // t: any,
  // extraParams: any
): ContactBlockProps & {
  sendLabel?: string
  sendPendingLabel?: string
  sendConfirmationLabel?: string
} => {
  return {
    title: props?.title ?? '',
    hash: props?.hash ?? 'contact',
    text: RichTextTransformer(props?.text?.json),
    fieldsProps: {
      name: {
        label: props?.nameLabel ?? '',
        placeholder: props?.namePlaceholder ?? '',
      },
      email: {
        label: props?.emailLabel ?? '',
        placeholder: props?.emailPlaceholder ?? '',
      },
      subject: {
        label: props?.subjectLabel ?? '',
        placeholder: props?.subjectPlaceholder ?? '',
        options: props?.subjectOptions
          ?.filter((option) => !!option)
          ?.map((option) => ({ value: option as string, label: option as string })),
      },
      message: {
        label: props?.messageLabel ?? '',
        placeholder: props?.messagePlaceholder ?? '',
      },
    },
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    mandatoryText: props?.required ?? '',
    errorTexts: {
      required: props?.validationRequired ?? '',
      email: props?.validationEmail ?? '',
    },
    sendLabel: props?.sendLabel ?? '',
    sendPendingLabel: props?.sendPendingLabel ?? '',
    sendConfirmationLabel: props?.sendConfirmationLabel ?? '',
  }
}
