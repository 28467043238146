import React, { FC } from 'react'

import { LinkProps } from '../Link'

import * as SC from './styled'

export type QuestionBlockProps = {
  className?: string
  title: string
  text?: string
  cta: LinkProps
}

const QuestionBlock: FC<QuestionBlockProps> = (props) => {
  const { className, title, text, cta } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.CtaButton {...cta} />
      </SC.Wrapper>
    </SC.Box>
  )
}

export default QuestionBlock
