/* eslint-disable react/display-name */
import React, { FC, useMemo } from 'react'

import * as SC from './styled'

type ContentfulProps = {
  children: React.ReactNode
  typename: string
  datas?: any
  className?: string
  debugMode?: boolean
  linkSpace: string
}

const Contentful: FC<ContentfulProps> = ({
  children,
  typename,
  linkSpace,
  datas = {},
  debugMode = false,
  className = '',
}) => {
  const propsEdit = useMemo(
    () => ({
      text: typename
        .replace('Contentful_', '')
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/([A-Z][a-z])/g, ' $1'),
      linkProps: { link: `${linkSpace}/${datas?.sys?.id}`, target: '_blank' },
    }),
    [typename, linkSpace, datas?.sys?.id]
  )

  if (!debugMode || !datas || !datas.sys || !datas.sys.id) {
    return children
  }

  return (
    <SC.Container className={className}>
      <SC.EditWrapper>
        <SC.EditButton {...propsEdit?.linkProps}>{propsEdit?.text}</SC.EditButton>
      </SC.EditWrapper>
      {children}
    </SC.Container>
  )
}

export default Contentful
