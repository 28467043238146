import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import SwiperCore, { SwiperOptions, Pagination, Navigation } from 'swiper'
import Swiper from 'swiper/types/swiper-class'
import { SwiperSlide } from 'swiper/react'

import { Icons } from '../Icon/types'
import useWindowSize from '../../hooks/useWindowSize'
import { ImageGatsbyProps } from '../ImageGatsby'

import * as SC from './styled'

SwiperCore.use([Navigation, Pagination])

export type QuoteProps = {
  text: string
  name: string
  photo: ImageGatsbyProps
  description: string
}

export type QuoteBlockProps = {
  className?: string
  title: string
  quotes: QuoteProps[]
  backgroundImage: ImageGatsbyProps
}

const QuoteBlock: FC<QuoteBlockProps> = (props) => {
  const { className, title, quotes, backgroundImage } = props
  const [isMobile, setIsMobile] = useState(false)

  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>()
  const [lastSlide, setLastSlide] = useState(false)
  const [firstSlide, setFirstSlide] = useState(false)

  const { width } = useWindowSize()

  useEffect(() => {
    if (width < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  const swiperOptions: SwiperOptions = {
    slidesPerView: 'auto',
  }

  const handleSetSlide = useCallback((swiper) => {
    setFirstSlide(swiper.isBeginning ?? false)
    setLastSlide(swiper.isEnd ?? false)
  }, [])

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<div class="' + className + '"></div>'
    },
  }

  const renderCard = (quote: QuoteProps, i: number) => {
    return (
      <SC.Quote key={i}>
        <SC.QuoteIcon icon={Icons.quote} />
        <SC.QuoteText>{quote.text}</SC.QuoteText>
        <SC.QuoteProfile>
          <SC.QuoteImage {...quote.photo} />
          <SC.QuoteInfo>
            <SC.QuoteName>{quote.name}</SC.QuoteName>
            <SC.QuoteDescription>{quote.description}</SC.QuoteDescription>
          </SC.QuoteInfo>
        </SC.QuoteProfile>
      </SC.Quote>
    )
  }

  const renderSlides = useMemo(() => {
    return (
      quotes &&
      quotes.map((quote, i) => {
        return <SwiperSlide key={'slider-${i}'}>{renderCard(quote, i)}</SwiperSlide>
      })
    )
  }, [quotes])

  const renderContent = () => (
    <SC.Quotes>{quotes.map((quote, i) => renderCard(quote, i))}</SC.Quotes>
  )

  const renderMobileSwiper = () => (
    <>
      <SC.Slider
        {...swiperOptions}
        pagination={pagination}
        onSwiper={(swiper) => {
          setSwiperInstance(swiper)
          handleSetSlide(swiper)
        }}
        onSlideChange={(swiper) => handleSetSlide(swiper)}
        onReachEnd={() => setLastSlide(true)}
        allowTouchMove={true}
        initialSlide={quotes.length / 2}
      >
        {renderSlides}
      </SC.Slider>
    </>
  )

  return (
    <SC.Box className={className}>
      {backgroundImage && (
        <SC.BoxBackgroundImage>
          <SC.BackgroundImage {...backgroundImage} />
        </SC.BoxBackgroundImage>
      )}
      <SC.Wrapper disableGutters>
        <SC.Content maxWidth="xl" disableGutters>
          <SC.Title>{title}</SC.Title>
          {isMobile ? renderMobileSwiper() : renderContent()}
        </SC.Content>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default QuoteBlock
