import { Contentful_HpFaqBlock, Contentful_HpFaqBlockItem, Maybe } from '../../types/gatsby-types'
import { QuestionProps, FAQBlockProps } from '../../components/FAQBlock'
import { Icons } from '../../components/Icon/types'
import { LinkTextTransformer } from '../models'

const HpFaqItemsTransformer = (hpfaqItem: Maybe<Contentful_HpFaqBlockItem>): QuestionProps => {
  return {
    title: hpfaqItem?.title || '',
    icon: (hpfaqItem?.icon as Icons) || Icons.soutien,
    cta: hpfaqItem?.cta ? LinkTextTransformer(hpfaqItem.cta) : { text: '' },
  }
}

export default (
  props: Maybe<Contentful_HpFaqBlock> & { hpfaqBlockText: string }
  // t: any,
  // extraParams: any
): FAQBlockProps => {
  return {
    title: props?.title as string,
    questions:
      props?.questionsCollection?.items?.map((item: Maybe<Contentful_HpFaqBlockItem>) =>
        HpFaqItemsTransformer(item)
      ) ?? [],
    cta: props?.cta ? LinkTextTransformer(props?.cta) : { text: '' },
  }
}
