import styled from 'styled-components'
import { Container } from '@material-ui/core'
import { Swiper } from 'swiper/react'

import Icon from '../Icon'
import Image from '../Image'

export const Box = styled.div`
  position: relative;
`

export const BoxBackgroundImage = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export const BackgroundImage = styled(Image)`
  width: 60rem;
  margin-bottom: -15%;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 100%;
    margin-bottom: -10%;
  }
`

export const Wrapper = styled(Container)`
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  border-bottom-left-radius: 100% 5%;
  border-bottom-right-radius: 100% 5%;
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
    border-bottom-left-radius: 100% 15%;
    border-bottom-right-radius: 100% 15%;
  }
`

export const Content = styled(Container)``

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    margin: 0;
  }
`

export const Quotes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 6rem;
`

export const Quote = styled.div`
  max-width: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    align-content: flex-start;
    align-items: flex-start;
  }
`

export const QuoteIcon = styled(Icon)`
  width: 5.2rem;
  height: 4.4rem;
`

export const QuoteText = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  text-align: left;
  width: 100%;
`

export const QuoteProfile = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`

export const QuoteImage = styled(Image)`
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 2rem;
`

export const QuoteInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const QuoteName = styled.p`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const QuoteDescription = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0;
`

export const Slider = styled(Swiper)`
  &,
  & * {
    -webkit-tap-highlight-color: transparent;
  }
  &.swiper-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0 0 5rem;
    width: 100%;
    /* Fix of Webkit flickering */
    z-index: 1;
  }
  &.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  & .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: stretch;
    transition-property: transform;
    box-sizing: content-box;
  }
  &.swiper-container-android .swiper-slide,
  & .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  &.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  &.swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  &.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  &.swiper-container-pointer-events {
    touch-action: pan-y;
    &.swiper-container-vertical {
      touch-action: pan-x;
    }
  }
  & .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-shrink: 0;
    width: 100%;
    height: auto; // dealt with by "stretch in wrapper"
    position: relative;
    transition-property: transform;
  }
  & .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  &.swiper-container-autoheight {
    &,
    .swiper-slide {
      height: auto;
    }
    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
  }
  /* 3D Effects */
  &.swiper-container-3d {
    perspective: 1200px;
    .swiper-wrapper,
    .swiper-slide,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom,
    .swiper-cube-shadow {
      transform-style: preserve-3d;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right,
    .swiper-slide-shadow-top,
    .swiper-slide-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
    }
    .swiper-slide-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
    .swiper-slide-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
  }
  /* CSS Mode */
  &.swiper-container-css-mode {
    > .swiper-wrapper {
      overflow: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: start start;
    }
  }
  &.swiper-container-horizontal.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: x mandatory;
    }
  }
  &.swiper-container-vertical.swiper-container-css-mode {
    > .swiper-wrapper {
      scroll-snap-type: y mandatory;
    }
  }
  // Pagination
  & .swiper-pagination {
    display: flex;
    align-items: center;
    z-index: 5;
    max-width: max-content;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  & .swiper-pagination-bullet {
    width: 1.8rem;
    height: 1.8rem;
    background-color: ${(props) => props.theme.palette.colors.lightViolet};
    border-radius: 50%;
    margin: 1rem;
  }
  & .swiper-pagination-bullet-active {
    width: 2.4rem;
    height: 2.4rem;
    background-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    &.swiper-container {
      padding-bottom: 4rem;
      min-height: 10rem;
    }
  }
` as typeof Swiper
