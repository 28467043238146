import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import LinkButton from '../LinkButton'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.colors.white};
  border-bottom-left-radius: 100% 5%;
  border-bottom-right-radius: 100% 5%;
  ${(props) => props.theme.breakpoints.up('md')} {
    border-bottom-left-radius: 100% 15%;
    border-bottom-right-radius: 100% 15%;
  }
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  font-size: 2.8rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 3.6rem;
    margin: 0;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4rem 1rem 1rem 1rem;
  }
`

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
    margin-right: 3rem;
  }
`

export const QuestionBoxIcon = styled.div`
  position: relative;
  max-width: max-content;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10%;
    height: 1px;
    width: 50%;
    border-bottom: 3px solid ${(props) => props.theme.palette.colors.neonCarrot};
    border-radius: 0.3rem;
  }
`

export const QuestionIcon = styled(Icon)`
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const QuestionTitle = styled.h4`
  text-align: start;
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  ${(props) => ({ ...props.theme.typography.h4 })}
`

export const CtaButton = styled(LinkButton)`
  margin-top: 5rem;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  border-color: ${(props) => props.theme.palette.colors.neonCarrot};
  & > span {
    ${(props) => ({ ...props.theme.typography.h6 })}
    line-height: 2.25;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.neonCarrot};
    border-color: ${(props) => props.theme.palette.colors.neonCarrot};
    & > span {
      color: ${(props) => props.theme.palette.background.paper};
    }
  }
`

export const Cta = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h6 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
`
