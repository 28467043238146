import React, { FC, memo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { WindowLocation } from '@reach/router'

import { PageTemplate } from '../../templates'
import App from '../../components/App'
import {
  Contentful_AssetCollection,
  Contentful_Configuration,
  Contentful_LinkCollection,
  Contentful_Page,
} from '../../types/gatsby-types'
import { SeoProps } from '../../components/Seo'
import Blocks from '../../relay/Blocks'
import ContentfulDebugBar from '../../components/ContentfulDebugBar'
import { contentful } from '../../configuration'
import { useHeader } from '../../hooks/useHeader'
import { useFooter } from '../../hooks/useFooter'

interface ContentfulPage extends PageProps {
  location: WindowLocation
  data: {
    contentful: { page: Contentful_Page }
  }
  pageContext: {
    configuration: Contentful_Configuration
    seo: SeoProps
    locale: string
    assetsCollection: Contentful_AssetCollection | null
    linksCollection: Contentful_LinkCollection | null
  }
}
const PageContainer: FC<ContentfulPage> = ({
  location,
  data: {
    contentful: { page },
  },
  pageContext: { seo, configuration, locale, assetsCollection, linksCollection },
}) => {
  const { headerProps } = useHeader(configuration, locale, location, page)
  const { footerProps } = useFooter(configuration, locale)
  const pageCommons = {
    color: page?.color,
  }

  return (
    <App seo={seo} locale={locale} configuration={configuration}>
      <PageTemplate headerProps={headerProps} footerProps={footerProps}>
        {page?.blocksCollection && page?.blocksCollection.total > 0 && (
          <Blocks
            blocks={page?.blocksCollection?.items}
            extraParams={{
              pageCommons,
              locale,
              configuration,
              assetsCollection,
              linksCollection,
            }}
          />
        )}
      </PageTemplate>
      <ContentfulDebugBar
        typename={page?.__typename ?? ''}
        datas={page}
        debugMode={contentful.debugEdition}
        linkSpace={contentful.linkSpace}
      />
    </App>
  )
}

export default memo(PageContainer)

export const query = graphql`
  query($id: String!, $preview: Boolean, $locale: String) {
    contentful {
      page(id: $id, preview: $preview, locale: $locale) {
        __typename
        sys {
          id
        }
        color
        slug
        blocksCollection(limit: 10) {
          total
          items {
            __typename
            ...CheckmarkBlock
            ...HeroBlock
            ...TeachersBlock
            ...VideoBlock
            ...ConceptBlock
            ...FeatureBlock
            ...CloudBlock
            ...QuoteBlock
            ...HpFaqBlock
            ...QuestionBlock
            ...FaqSectionBlock
            ...HelpBlock
            ...FaqRelatedBlock
            ...BreadcrumbBlock
            ...ContactBlock
            ...TextBlock
          }
        }
      }
    }
  }
`
