import React, { FC, ReactNode } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'
import { ImgProps } from '../../types/image'

import * as SC from './styled'

export type TeachersBlockProps = {
  className?: string
  title: string
  text: ReactNode | string
  keypoints: string[]
  cta?: LinkProps
  image: ImgProps
}

const TeachersBlock: FC<TeachersBlockProps> = (props) => {
  const { className, title, text, keypoints, cta, image } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Intro>
          <SC.Title>{title}</SC.Title>
          <SC.Text>{text}</SC.Text>
        </SC.Intro>
        <SC.Keypoints>
          {keypoints.map((keypoint, index) => (
            <SC.Keypoint key={index}>
              <SC.KeypointIcon icon={Icons.star} />
              <SC.KeypointText>{keypoint}</SC.KeypointText>
            </SC.Keypoint>
          ))}
        </SC.Keypoints>
        <SC.BoxImage>
          <SC.StyledImage {...image} />
        </SC.BoxImage>
        {cta && (
          <SC.Cta {...cta} icon={Icons.longArrowRight} iconColor={light.colors.freeSpeechBlue} />
        )}
      </SC.Wrapper>
    </SC.Box>
  )
}

export default TeachersBlock
