import { Contentful_VideoBlock, Maybe } from '../../types/gatsby-types'

export const VideoContentTransformer = (props: Maybe<Contentful_VideoBlock>) => ({
  title: props?.title ?? '',
  credit: props?.video?.title ?? '',
  text: props?.video?.description ?? '',
  poster: props?.cover?.url,
  youtube: props?.youtube,
  sources: [
    {
      type: props?.video?.contentType ?? '',
      src: props?.video?.url ?? '',
    },
  ],
})

export default (props: Maybe<Contentful_VideoBlock>) => VideoContentTransformer(props)
