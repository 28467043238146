import React from 'react'

import * as SC from './styled'

export type FormSubmitErrorsProps = {
  submitErrors?: string[]
}

const FormSubmitErrors: React.FC<FormSubmitErrorsProps> = ({ submitErrors }) => {
  if (!submitErrors || !(submitErrors?.length > 0)) return null

  return (
    <SC.Container>
      {submitErrors?.map((error, key) => (
        <SC.Error key={key}>{error}</SC.Error>
      ))}
    </SC.Container>
  )
}

export default FormSubmitErrors
