import React from 'react'
import { PropTypes } from '@material-ui/core'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type FormSubmitProps = {
  className?: string
  text?: string
  pendingText?: string
  successText?: string
  disabledText?: string
  type?: 'submit' | 'button'
  color?: PropTypes.Color
  onClickHandler?: (e: any | undefined) => void
  isPending?: boolean
  isSuccess?: boolean
  isDisabled?: boolean
  icon?: Icons
  isFullSize?: boolean
}

const FormSubmit: React.FC<FormSubmitProps> = ({
  className,
  text,
  children,
  type,
  color,
  onClickHandler,
  isPending,
  isSuccess,
  isDisabled,
  successText,
  pendingText,
  disabledText,
  icon,
  isFullSize,
}) => {
  const displayText = isPending ? pendingText ?? text : isSuccess ? successText ?? text : text
  return (
    <>
      {!isFullSize && (
        <SC.Button
          className={className}
          onClick={onClickHandler}
          type={type ?? 'submit'}
          variant="contained"
          color={color}
          $isPending={isPending}
          $isSuccess={isSuccess}
          disableElevation
          disabled={isDisabled}
        >
          {icon && <SC.StyledIcon icon={icon} />}
          {children || displayText}
        </SC.Button>
      )}
      {isFullSize && (
        <>
          <SC.Placeholder />
          <SC.StyledFormSubmit
            className={className}
            onClick={onClickHandler}
            type={type ?? 'submit'}
            variant="contained"
            color={color}
            $isPending={isPending}
            $isSuccess={isSuccess}
            disableElevation
            disabled={isDisabled}
          >
            {icon && <SC.StyledIcon icon={icon} />}
            {children || displayText}
          </SC.StyledFormSubmit>
        </>
      )}
    </>
  )
}
export default FormSubmit
