import styled from 'styled-components'
import { Button as MuiButton } from '@material-ui/core'

import Icon from '../Icon'

export const Button = styled(MuiButton)<{
  $isPending?: boolean
  $isSuccess?: boolean
}>`
  ${(props) =>
    props?.$isPending &&
    `
    opacity: 0.5;
    cursor: no-drop;
    pointer-events: none;
  `}
  ${(props) =>
    props?.$isSuccess &&
    `
    opacity: 0.5;
    cursor: no-drop;
    pointer-events: none;
  `}
`

export const StyledIcon = styled(Icon)`
  height: 3.2rem;
  width: 3.2rem;
  transform: translateY(0.4rem);
  margin-right: 1rem;
  color: ${(props) => props.theme.palette.colors.white};
  z-index: 1;
  transform: translateY(-0.1rem);
`

export const Placeholder = styled.div`
  height: 6.7rem;
  margin-top: 2rem;
`

export const StyledFormSubmit = styled(MuiButton)<{
  $isPending?: boolean
  $isSuccess?: boolean
}>`
  height: 6.7rem;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.values.sm}px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  color: ${(props) => props.theme.palette.colors.white};
  ${(props) =>
    props?.$isPending &&
    `
    opacity: 0.5;
    cursor: no-drop;
    pointer-events: none;
  `}
  ${(props) =>
    props?.$isSuccess &&
    `
    opacity: 0.5;
    cursor: no-drop;
    pointer-events: none;
  `}
`
