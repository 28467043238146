import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type QuestionProps = {
  icon: Icons
  title: string
  cta: LinkProps
}

export type FAQBlockProps = {
  className?: string
  title: string
  questions: QuestionProps[]
  cta: LinkProps
}

const FAQBlock: FC<FAQBlockProps> = (props) => {
  const { className, title, questions, cta } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.Questions>
          {questions.map((question, index) => (
            <SC.Question key={index}>
              <SC.QuestionBoxIcon>
                <SC.QuestionIcon icon={question.icon} />
              </SC.QuestionBoxIcon>
              <SC.QuestionTitle>{question.title}</SC.QuestionTitle>
              <SC.Cta
                {...question.cta}
                icon={Icons.longArrowRight}
                iconColor={light.colors.freeSpeechBlue}
              />
            </SC.Question>
          ))}
        </SC.Questions>
        <SC.CtaButton {...cta} variant="outlined" />
      </SC.Wrapper>
    </SC.Box>
  )
}

export default FAQBlock
