import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    padding: 5rem 13rem 10rem 13rem;
    align-items: center;
  }
`

export const Intro = styled.div`
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  font-size: 2.8rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 3.6rem;
    margin: 0;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Concepts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
`

export const Concept = styled.div<{ isCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: ${(props) => (props.isCentered === true ? 'center' : 'left')};
  align-items: ${(props) => (props.isCentered === true ? 'center' : 'flex-start')};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 30rem;
    margin-top: 6rem;
  }
`

export const ConceptIcon = styled(Icon)`
  height: 8rem;
  width: 8rem;
  margin-bottom: 1rem;
`

export const ConceptTitle = styled.h4`
  ${(props) => ({ ...props.theme.typography.h4 })};
  color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  margin: 0;
`

export const ConceptText = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`
