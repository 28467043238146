import styled from 'styled-components'
import { Container } from '@material-ui/core'

import LinkButton from '../LinkButton'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.background.ghostWhite};
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem 13rem;
    max-width: 95rem;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.2rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 4rem;
    margin: 0;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 4rem;
  }
`

export const CtaButton = styled(LinkButton)`
  margin-bottom: 4rem;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  background-color: ${(props) => props.theme.palette.colors.neonCarrot};
  & > span {
    ${(props) => ({ ...props.theme.typography.h6 })}
    color: ${(props) => props.theme.palette.colors.pureWhite};
    line-height: 2.25;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.kournikova};
    border-color: ${(props) => props.theme.palette.colors.kournikova};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: 3rem;
  }
`
