import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import { Icons } from '../../Icon/types'

import * as SC from './styled'

export type RubricPartFAQProps = {
  className?: string
  title: string
  text?: ReactNode | string
  hash?: string
  isOpen?: boolean
  onChange?: (value: boolean) => void
}

const RubricPartFAQ: FC<RubricPartFAQProps> = (props) => {
  const { className, hash, title, text, isOpen = false, onChange } = props
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen)

  const onToggle = useCallback(() => {
    if (
      typeof onChange !== 'undefined' &&
      typeof isOpen !== 'undefined' &&
      typeof hash !== 'undefined'
    ) {
      onChange?.(!isOpenState)
    } else {
      setIsOpenState(!isOpenState)
    }
  }, [isOpenState, onChange])

  useEffect(() => {
    setIsOpenState(isOpen)
  }, [isOpen])

  return (
    <SC.Box className={className} id={hash}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Question isOpen={isOpenState} onClick={onToggle}>
          <SC.QuestionHead>
            <SC.QuestionTitle>{title}</SC.QuestionTitle>
            <SC.QuestionIcon isOpen={isOpenState} icon={Icons.chevronDown} />
          </SC.QuestionHead>
          <SC.QuestionText isOpen={isOpenState}>{text}</SC.QuestionText>
        </SC.Question>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default RubricPartFAQ
