import React, { ChangeEvent, FC, FocusEventHandler } from 'react'

import FieldLabel from '../FieldLabel'

import * as SC from './styled'

export type TextFieldProps = {
  className?: string
  name: string
  label?: string
  placeholder?: string
  select?: boolean
  required?: boolean
  multiline?: boolean
  error?: string
  helpText?: string
  value?: string
  onChange?: (e: ChangeEvent) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    className,
    name,
    label,
    placeholder,
    required,
    multiline,
    error,
    helpText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        {label && <FieldLabel label={label} required={required} hasError={!!error} />}
        <SC.Field
          name={name}
          placeholder={placeholder}
          multiline={multiline}
          fullWidth
          rows={4}
          variant="outlined"
          helperText={error ? error : helpText}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </SC.Wrapper>
    </SC.Box>
  )
}

export default TextField
