import React, { FC } from 'react'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type Concept = {
  icon: Icons
  title: string
  text: string
}

export type ConceptBlockProps = {
  className?: string
  title: string
  text?: string
  concepts: Concept[]
  centered?: boolean
}

const ConceptBlock: FC<ConceptBlockProps> = (props) => {
  const { className, title, text, concepts, centered } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Intro>
          <SC.Title>{title}</SC.Title>
          {text && <SC.Text>{text}</SC.Text>}
        </SC.Intro>
        <SC.Concepts>
          {concepts.map((concept, index) => (
            <SC.Concept key={index} isCentered={centered}>
              <SC.ConceptIcon icon={concept.icon} />
              <SC.ConceptTitle>{concept.title}</SC.ConceptTitle>
              <SC.ConceptText>{concept.text}</SC.ConceptText>
            </SC.Concept>
          ))}
        </SC.Concepts>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default ConceptBlock
