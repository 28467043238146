import { Contentful_ConceptBlock, Contentful_ConceptItem, Maybe } from '../../types/gatsby-types'
import { Concept, ConceptBlockProps } from '../../components/ConceptBlock'
import { Icons } from '../../components/Icon/types'

const ConceptItemsTrasnformer = (conceptItem: Maybe<Contentful_ConceptItem>): Concept => {
  return {
    title: conceptItem?.title || '',
    text: conceptItem?.description || '',
    icon: (conceptItem?.icon as Icons) || Icons.soutien,
  }
}

export default (
  props: Maybe<Contentful_ConceptBlock> & { conceptBlockText: string }
  // t: any,
  // extraParams: any
): ConceptBlockProps => {
  return {
    title: props?.title as string,
    text: props?.conceptBlockText as string,
    concepts:
      props?.conceptsCollection?.items?.map((item: Maybe<Contentful_ConceptItem>) =>
        ConceptItemsTrasnformer(item)
      ) ?? [],
    centered: !!props?.centered,
  }
}
