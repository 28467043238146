import {
  Contentful_FaqQuestion,
  Contentful_FaqSectionBlock,
  Contentful_HelpBlock,
  Maybe,
} from '../../types/gatsby-types'
import { HelpBlockProps } from '../../components/HelpBlock'
import { RichTextTransformer } from '../models'
import { faqIconTransform, getFaqSectionPageLink } from '../FaqSection/transform'

export default (props: Maybe<Contentful_HelpBlock>, t: any, extraParams: any): HelpBlockProps => ({
  categories: props?.sectionsCollection?.items?.map(
    (section: Maybe<Contentful_FaqSectionBlock>) => ({
      title: section?.title ?? '',
      text: RichTextTransformer(section?.text?.json),
      questions: section?.questionsCollection?.items
        ?.slice(0, 2)
        ?.map((q: Maybe<Contentful_FaqQuestion>) => ({
          question: q?.title ?? '',
          link: getFaqSectionPageLink(section) + '#' + q?.hash ?? '',
        })),
      cta:
        section?.questionsCollection?.total && section?.questionsCollection?.total > 2
          ? {
              text: t('faq_see_all', { count: section?.questionsCollection?.total }),
              link: getFaqSectionPageLink(section),
              target: '_self',
            }
          : undefined,
      icon: faqIconTransform(section?.icon),
      color: section?.color,
    })
  ),
})
