import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'

import * as SC from './styled'

export type CardProps = {
  icon: Icons
  color?: string
  title: string
  cta: LinkProps
}

export type SeeAlsoProps = {
  className?: string
  title: string
  cards?: CardProps[]
}

const SeeAlso: FC<SeeAlsoProps> = (props) => {
  const { className, title, cards } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.Cards>
          {cards?.map((card, index) => (
            <SC.Card key={index}>
              <SC.CardIcon icon={card.icon} color={card.color} />
              <SC.CardTitle>{card.title}</SC.CardTitle>
              <SC.CardCta
                {...card.cta}
                icon={Icons.longArrowRight}
                iconColor={light.colors.freeSpeechBlue}
              />
            </SC.Card>
          ))}
        </SC.Cards>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default SeeAlso
