import React, { FC } from 'react'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type PebbleIconProps = {
  className?: string
  icon: Icons
  color?: string
  isActive?: boolean
  onClick?: (id?: string) => void
}

const PebbleIcon: FC<PebbleIconProps> = ({ className, icon, color }) => {
  return (
    <SC.Container className={className}>
      <SC.Pebble icon={Icons.pebble} color={color} />
      <SC.Icon icon={icon} color={'#FFFFFF'} />
    </SC.Container>
  )
}

export default PebbleIcon
