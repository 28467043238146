import { Contentful_CloudBlock, Maybe } from '../../types/gatsby-types'
import { CloudBlockProps } from '../../components/CloudBlock'
import { LinkTextTransformer } from '../models'

export default (
  props: Maybe<Contentful_CloudBlock>
  // t: any,
  // extraParams: any
): CloudBlockProps => {
  return {
    title: props?.title as string,
    cta: props?.cta ? LinkTextTransformer(props?.cta) : { text: '' },
    backgroundImage: { image: 'cloud-how-blue', alt: '' },
  }
}
