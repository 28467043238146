import React, { ChangeEvent, FC, FocusEventHandler } from 'react'

import FieldLabel from '../FieldLabel'

import * as SC from './styled'

export type Option = {
  value: string
  label: string
}

export type SelectProps = {
  className?: string
  name?: string
  label?: string
  placeholder?: string
  required?: boolean
  error?: string
  helpText?: string
  options?: Option[]
  value?: string
  onChange?: (e: ChangeEvent) => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

const Select: FC<SelectProps> = (props) => {
  const {
    className,
    name,
    label,
    placeholder,
    required,
    options,
    error,
    helpText,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        {label && <FieldLabel label={label} required={required} hasError={!!error} />}
        <SC.Field
          name={name}
          SelectProps={{
            displayEmpty: !!placeholder,
          }}
          fullWidth
          rows={4}
          variant="outlined"
          helperText={error ? error : helpText}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          select
        >
          {placeholder && (
            <SC.Option disabled value="">
              {placeholder}
            </SC.Option>
          )}
          {options?.map((option, index) => (
            <SC.Option key={index} value={option.value}>
              {option.label}
            </SC.Option>
          ))}
        </SC.Field>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default Select
