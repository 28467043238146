import styled from 'styled-components'

export const VideoContent = styled.div<{ isWithoutPadding?: boolean; isInline?: boolean }>`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
  padding: 2rem 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem 1rem;
  }
  ${(props) => props.isWithoutPadding && `padding: 0;`}
`
export const Figure = styled.figure`
  max-width: 96rem;
  margin: 0 auto;
`

export const VideoYoutube = styled.div`
  position: relative;
  height: 0;
  padding-top: ${(9 / 16) * 100}%;
  width: 100%;
  & iframe {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const Video = styled.video`
  display: block;
  width: 100%;
`
export const Caption = styled.figcaption`
  padding-left: 2rem;
  padding-right: 2rem;
  color: ${(props) => props.theme.palette.colors.greyMid66};
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: 0;
    padding-right: 0;
  }
`
export const Credit = styled.div<{ isBig?: boolean }>`
  ${(props) => ({ ...props.theme.typography.tinyStrong })}
  margin-top: ${(props) => (props.isBig === true ? '2rem' : '0')};
`
export const Text = styled.div<{ isBig?: boolean }>`
  ${(props) => ({ ...props.theme.typography.smallRegular })}
  &:not(:first-child) {
    margin-top: 1rem;
  }
`
