import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import Image from '../Image'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  border-bottom-left-radius: 100% 5%;
  border-bottom-right-radius: 100% 5%;
  ${(props) => props.theme.breakpoints.up('md')} {
    border-bottom-left-radius: 100% 15%;
    border-bottom-right-radius: 100% 15%;
  }
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
  }
`

export const Intro = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 60rem;
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h1 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 3.6rem;
    margin: 0;
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Keypoints = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
  }
`

export const Keypoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
    margin-right: 3rem;
  }
`

export const KeypointIcon = styled(Icon)`
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 1rem;
`

export const KeypointText = styled.h5`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  max-width: 30rem;
  margin: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
  }
`

export const Cta = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 4rem 0;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h5 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
`

export const BoxImage = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 60rem;
    margin-top: 4rem;
  }
`

export const StyledImage = styled(Image)`
  height: 7.4rem;
  width: 28rem;
  z-index: 10;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 13.5rem;
    width: 50rem;
    max-height: 50rem;
    max-width: 64rem;
  }
`
