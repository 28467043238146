import styled from 'styled-components'
import { Container } from '@material-ui/core'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  padding-top: 3rem;
  width: 100%;
`

export const Wrapper = styled(Container)`
  position: relative;
  width: 100%;
`

export const Text = styled.div`
  padding: 1rem;
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  & h1 {
    ${(props) => ({ ...props.theme.typography.h1 })};
  }
  & h2 {
    ${(props) => ({ ...props.theme.typography.h2 })};
  }
  & h3 {
    ${(props) => ({ ...props.theme.typography.h3 })};
  }
  & h4 {
    ${(props) => ({ ...props.theme.typography.h4 })};
  }
  & h5 {
    ${(props) => ({ ...props.theme.typography.h5 })};
  }
  & h6 {
    ${(props) => ({ ...props.theme.typography.h6 })};
  }
`
