import React, { FC, ReactNode } from 'react'

import { TextLinkProps } from '../Link'
import { ImgProps } from '../../types/image'
import { ImageProps } from '../Image'

import * as SC from './styled'

export type HeroProps = {
  className?: string
  title: string
  text?: ReactNode
  cta?: TextLinkProps
  image: ImageProps
  backgroundColor: string
  backgroundImage?: ImgProps
  isFAQ?: boolean
}

const Hero: FC<HeroProps> = (props) => {
  const { className, title, text, image, cta, backgroundColor, backgroundImage, isFAQ } = props

  return (
    <SC.Hero className={className} backgroundColor={backgroundColor}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          {text && <SC.Text>{text}</SC.Text>}
          {cta && <SC.Cta {...cta} />}
        </SC.Content>
        <SC.BoxHeroImage>
          <SC.HeroImage {...image} objectFit={'contain'} />
        </SC.BoxHeroImage>
      </SC.Wrapper>
      {backgroundImage && (
        <SC.BoxBackgroundImage isFAQ={isFAQ}>
          <SC.BackgroundImage src={backgroundImage.src} alt={backgroundImage.alt} />
        </SC.BoxBackgroundImage>
      )}
    </SC.Hero>
  )
}

export default Hero
