import { Contentful_CheckmarkBlock, Maybe } from '../../types/gatsby-types'
import { ImageTransformer, LinkTextTransformer, RichTextTransformer } from '../models'
import { CheckmarkBlockProps } from '../../components/CheckmarkBlock'

export default (
  props: Maybe<Contentful_CheckmarkBlock>,
  t: any,
  extraParams: any
): CheckmarkBlockProps => {
  return {
    title: props?.title as string,
    text: RichTextTransformer(props?.text?.json),
    checkmarks: props?.checkmarks as string[],
    image: ImageTransformer(props?.image ?? null),
    cta: props?.cta ? LinkTextTransformer(props?.cta) : undefined,
    ...(props?.youtube && {
      video: {
        youtube: props?.youtube || undefined,
      },
    }),
  }
}
