import { Contentful_QuoteBlock, Contentful_QuoteItem, Maybe } from '../../types/gatsby-types'
import { QuoteProps, QuoteBlockProps } from '../../components/QuoteBlock'
import { ImageTransformer } from '../models'

const QuoteItemsTrasnformer = (quoteItem: Maybe<Contentful_QuoteItem>): QuoteProps => {
  return {
    name: quoteItem?.name || '',
    text: quoteItem?.text || '',
    description: quoteItem?.description || '',
    photo: ImageTransformer(quoteItem?.photo ?? null),
  }
}

export default (
  props: Maybe<Contentful_QuoteBlock> & { quoteBlockText: string }
  // t: any,
  // extraParams: any
): QuoteBlockProps => {
  return {
    backgroundImage: { image: 'cloud-top', alt: '' },
    title: props?.title as string,
    quotes:
      props?.quotesCollection?.items?.map((item: Maybe<Contentful_QuoteItem>) =>
        QuoteItemsTrasnformer(item)
      ) ?? [],
  }
}
