import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ServiceProps } from '../helpers/ReduxHelpers'

export const useFormSubmit = (
  selector: any,
  action: any,
  actionReset: any,
  onComplete: any
): [ServiceProps, (values: any) => void] => {
  const [isPending, setIsPending] = useState(false)
  const dispatch = useDispatch()
  const submit: ServiceProps = useSelector(selector)
  const handleSubmit = useCallback(
    (values) => {
      setIsPending(true)
      dispatch?.(action(values))
    },
    [dispatch, action]
  )

  useEffect(() => {
    if (submit.success && isPending) {
      onComplete?.(submit)
    }
    if ((submit.pending || submit.complete) && !isPending) {
      dispatch?.(actionReset())
    }
  }, [
    dispatch,
    isPending,
    onComplete,
    actionReset,
    submit.success,
    submit.complete,
    submit.pending,
    submit,
  ])

  return [submit, handleSubmit]
}
