import { Contentful_FaqQuestion, Contentful_FaqSectionBlock, Maybe } from '../../types/gatsby-types'
import { RichTextTransformer } from '../models'
import { Icons } from '../../components/Icon/types'
import { RubricFAQProps } from '../../components/RubricFAQ'

export const faqIconTransform = (icon?: Maybe<string>) => (icon as Icons) ?? Icons.profile

export const getFaqSectionPageLink = (section: Maybe<Contentful_FaqSectionBlock>) =>
  '/' + section?.linkedFrom?.pageCollection?.items?.[0]?.slug ?? ''

export default (
  props: Maybe<Contentful_FaqSectionBlock>,
  t: any,
  extraParams: any
): RubricFAQProps => ({
  title: props?.title ?? '',
  text: RichTextTransformer(props?.text?.json),
  questions: props?.questionsCollection?.items?.map((q: Maybe<Contentful_FaqQuestion>, index) => ({
    title: q?.title ?? '',
    text: RichTextTransformer(q?.text?.json),
    hash: q?.hash ?? index + '',
    link: '#' + q?.hash ?? '#',
  })),
  icon: faqIconTransform(props?.icon),
  color: props?.color ?? '',
})
