import { Contentful_FeatureBlock, Maybe } from '../../types/gatsby-types'
import { FeatureBlockProps } from '../../components/FeatureBlock'
import { ImageTransformer, LinkTextTransformer, RichTextTransformer } from '../models'

export default (
  props: Maybe<Contentful_FeatureBlock>
  // t: any,
  // extraParams: any
): FeatureBlockProps => {
  return {
    title: props?.title as string,
    text: RichTextTransformer(props?.text?.json),
    cta: props?.cta ? LinkTextTransformer(props?.cta) : undefined,
    image: ImageTransformer(props?.image ?? null),
    isInverted: !!props?.isInverted,
    isInvertedMobile: !!props?.isInvertedMobile,
    ...(props?.youtube && {
      video: {
        youtube: props?.youtube || undefined,
      },
    }),
  }
}
