import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import PebbleIcon from '../PebbleIcon'

export const Box = styled.div``

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem;
  }
`

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
    margin-right: 5rem;
    flex: 1 0 40%;
  }
`

export const CategoryIcon = styled(PebbleIcon)`
  width: 6rem;
  height: 6rem;
`

export const CategoryTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })};
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const CategoryText = styled.p`
  ${(props) => ({ ...props.theme.typography.smallRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const CategoryBoxQuestions = styled.div`
  margin-bottom: 1rem;
`

export const CategoryQuestions = styled.div`
  padding: 2rem 1rem;
  border: 1px solid ${(props) => props.theme.palette.colors.lightGrey};
  margin-bottom: 2rem;
  border-radius: 0.8rem;
`

export const CategoryQuestion = styled.a`
  ${(props) => ({ ...props.theme.typography.h5 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  text-decoration: none;
`

export const CategoryCta = styled(Link)`
  ${(props) => ({ ...props.theme.typography.h6 })};
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  display: flex;
  align-items: center;
  max-width: max-content;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  margin-bottom: 2rem;
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
`
