import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Link from '../Link'

export const Box = styled.div`
  position: relative;
`

export const Wrapper = styled(Container)`
  max-width: 100%;
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
  position: relative;
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 1rem 3rem;
  }
`

export const Breadcrumb = styled.div``

export const BreadcrumbEntry = styled(Link)`
  text-decoration: none;
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  :not(:last-child) {
    color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  }
  :not(:last-child):after {
    content: ' / ';
    color: ${(props) => props.theme.palette.colors.grey};
    ${(props) => ({ ...props.theme.typography.mediumRegular })};
  }
`
