import React, { FC, useCallback } from 'react'
import { graphql } from 'gatsby'

import ContactBlock, { ContactBlockProps } from '../../components/ContactBlock'
import { FormSubmitProps } from '../../components/FormSubmit'
import { useFormSubmit } from '../../hooks/useFormSubmit'
import { actions, selectors } from '../../redux'

export type ContactProps = ContactBlockProps & {
  sendLabel?: string
  sendPendingLabel?: string
  sendConfirmationLabel?: string
}

const Contact: FC<ContactProps> = (props) => {
  const [submit, dispatchSubmit] = useFormSubmit(
    selectors.contact.contact,
    actions.contact.contactRequest,
    actions.contact.contactReset,
    null
  )
  const submitProps: FormSubmitProps = {
    text: props?.sendLabel,
    pendingText: props?.sendPendingLabel,
    successText: props?.sendConfirmationLabel,
    isPending: submit.pending,
    isSuccess: submit.success,
  }

  const handleSubmit = useCallback(
    (params: any) => {
      dispatchSubmit({ params })
    },
    [dispatchSubmit]
  )

  return (
    <ContactBlock
      {...props}
      submitProps={submitProps}
      onSubmit={handleSubmit}
      initialValues={submit.params || props?.initialValues}
    />
  )
}

export default Contact

export const query = graphql`
  fragment ContactBlock on Contentful_ContactBlock {
    title
    text {
      json
    }
    hash
    nameLabel
    namePlaceholder
    emailLabel
    emailPlaceholder
    subjectLabel
    subjectPlaceholder
    subjectOptions
    messageLabel
    messagePlaceholder
    required
    validationEmail
    validationRequired
    sendLabel
    sendPendingLabel
    sendConfirmationLabel
  }
`
