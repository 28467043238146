import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import Link from '../Link'
import PebbleIcon from '../PebbleIcon'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.colors.whiteSmoke};
`

export const Wrapper = styled(Container)`
  position: relative;
  padding: 2rem 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    padding: 5rem 13rem 10rem 13rem;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  font-size: 3.6rem;
  text-transform: none;
  margin: 0;
  margin-bottom: 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: 4rem;
  }
`

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  margin: -0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Card = styled.div<{ isCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.colors.pureWhite};
  border-radius: 0.8rem;
  align-items: center;
  padding: 3rem;
  margin: 0.8rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 33rem;
  }
`

export const CardIcon = styled(PebbleIcon)`
  height: 6rem;
  width: 6rem;
  margin-bottom: 1rem;
`

export const CardTitle = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })};
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const CardCta = styled(Link)`
  ${(props) => ({ ...props.theme.typography.h6 })};
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  display: flex;
  align-items: center;
  max-width: max-content;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  margin-top: 2rem;
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
`
