import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Link from '../Link'
import Image from '../Image'

export const Box = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
`

export const Wrapper = styled(Container)<{ $isInverted?: boolean; $isInvertedMobile?: boolean }>`
  position: relative;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: ${({ $isInvertedMobile }) => ($isInvertedMobile ? 'column' : 'column-reverse')};
  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: ${({ $isInverted }) => ($isInverted ? 'row-reverse' : 'row')};
    padding: 5rem;
  }
`

export const Content = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    max-width: 43rem;
    margin: 5rem 0;
  }
`

export const Intro = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 66rem;
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h2`
  ${(props) => ({ ...props.theme.typography.h2 })};
  color: ${(props) => props.theme.palette.colors.cornflowerBlue};
  font-size: 2.8rem;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 3.6rem;
    margin: 0;
  }
`

export const Text = styled.div`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
`

export const Cta = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  margin: 1rem 0;
  color: ${(props) => props.theme.palette.colors.neonCarrot};
  max-width: max-content;
  border-bottom: 2px solid transparent;
  ${(props) => ({ ...props.theme.typography.h5 })};
  &:hover {
    border-bottom: 2px solid ${(props) => props.theme.palette.colors.neonCarrot};
  }
`

export const BoxImage = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    position: relative;
    max-width: 60rem;
  }
`

export const Img = styled(Image)`
  height: 21rem;
  width: 32rem;
  z-index: 10;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 64rem;
    height: auto;
    max-height: 40rem;
    max-width: 64rem;
  }
`
