import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../Icon'
import PebbleIcon from '../PebbleIcon'

import RubricPartFAQ from './RubricPartFAQ'

export const Box = styled.div``

export const Wrapper = styled(Container)`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5rem 7rem;
  }
`

export const RubricIcon = styled(PebbleIcon)`
  width: 6rem;
  height: 6rem;
`

export const Title = styled.h3`
  ${(props) => ({ ...props.theme.typography.h3 })};
  color: ${(props) => props.theme.palette.colors.chambray};
  font-size: 2.4rem;
  text-transform: none;
  margin: 0;
  ${(props) => props.theme.breakpoints.up('md')} {
  }
`

export const Text = styled.p`
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  margin: 0;
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
`

export const Question = styled(RubricPartFAQ)``
