import React, { FC, useCallback } from 'react'

import * as components from '../../relay'
import * as transformers from '../transformers'
import { useIntl } from '../../intl'
import Contentful from '../../components/Contentful'
import { contentful as contentfulConfiguration } from '../../configuration'

export type BlocksProps = {
  blocks: any
  extraParams?: any
}

type Block = {
  __typename: string
}

const Blocks: FC<BlocksProps> = ({ blocks, extraParams = {} }) => {
  const t = useIntl()
  const renderBlock: any = useCallback(
    (block: Block, key: number) => {
      const Block: FC<any> = (components as any)?.[block?.__typename] ?? null

      if (!Block) {
        console.warn('Missing block : ' + block?.__typename)
        return null
      }
      const datas: any = (transformers as any)?.[block?.__typename]
        ? (transformers as any)?.[block?.__typename](block, t, extraParams)
        : block
      return (
        <Contentful
          typename={block?.__typename}
          datas={block}
          key={`${block?.__typename}-${key}`}
          debugMode={contentfulConfiguration.debugEdition}
          linkSpace={contentfulConfiguration.linkSpace}
        >
          <Block {...datas} {...extraParams} />
        </Contentful>
      )
    },
    [extraParams, t]
  )

  return <>{blocks?.map(renderBlock)}</>
}

export default Blocks
