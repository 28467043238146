import { Contentful_TeachersBlock, Maybe } from '../../types/gatsby-types'
import { ImageTransformer, LinkTextTransformer, RichTextTransformer } from '../models'
import { TeachersBlockProps } from '../../components/TeachersBlock'

export default (
  props: Maybe<Contentful_TeachersBlock>,
  t: any,
  extraParams: any
): TeachersBlockProps => {
  return {
    title: props?.title as string,
    text: RichTextTransformer(props?.text?.json),
    keypoints: props?.keypoints as string[],
    image: ImageTransformer(props?.image ?? null),
    cta: props?.cta ? LinkTextTransformer(props?.cta) : undefined,
  }
}
