import styled from 'styled-components'
import { Container } from '@material-ui/core'

import Icon from '../../Icon'

export const Box = styled.div``

export const Wrapper = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Question = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  box-shadow: ${(props) =>
    props.isOpen ? '0px 4px 30px rgba(0, 0, 0, 0.08)' : '0px 4px 30px rgba(0, 0, 0, 0.04)'};
  transition: all 0.5s ease;
  margin-bottom: ${(props) => (props.isOpen ? '2rem' : '0.3rem')};
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 100%;
  }
  cursor: pointer;
`

export const QuestionHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const QuestionIcon = styled(Icon)<{ isOpen?: boolean }>`
  width: 1.6rem;
  height: 1.6rem;
  color: ${(props) => props.theme.palette.colors.grey};
  transition: all 0.5s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`

export const QuestionTitle = styled.h6`
  ${(props) => ({ ...props.theme.typography.h5 })};
  text-align: start;
  color: ${(props) => props.theme.palette.colors.midnightBlue};
  margin: 0;
`

export const QuestionText = styled.p<{ isOpen?: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  text-align: start;
  color: ${(props) => props.theme.palette.colors.chambray};
`
