import styled from 'styled-components'

import { Link } from '../index'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.palette.colors.blueMain};
  z-index: 999;
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
  }
`

export const LinkItem = styled.li`
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => ({ ...props.theme.typography.mediumRegular })}

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: 1.5rem;
  }

  transition: opacity 300ms ease-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 300ms ease-out;
  }

  &:hover::after {
    opacity: 1;
  }

  cursor: pointer;
`

export const LinkInner = styled(Link)`
  color: white;
`
