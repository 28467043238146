import styled from 'styled-components'

import LinkButton from '../LinkButton'

export const Container = styled.div`
  position: relative;
`

export const EditWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  z-index: 999;

  transition: opacity 300ms ease-out;
  opacity: 0;
  visibility: hidden;

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }

  cursor: pointer;

  display: none;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
  }
`

export const EditButton = styled(LinkButton)``
