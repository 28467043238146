import React, { FC, ReactNode } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { FormSubmitProps } from '../FormSubmit'
import TextField from '../TextField'
import Select from '../Select'
import FormSubmitErrors from '../FormSubmitErrors'

import * as SC from './styled'

export type Option = {
  value: string
  label: string
}

export type FieldProps = {
  label?: string
  placeholder?: string
  select?: boolean
  required?: boolean
  multiline?: boolean
  options?: Option[]
}

export type ContactBlockValues = {
  name: string
  email: string
  subject: string
  message: string
}

export type ContactBlockProps = {
  className?: string
  title: string
  hash?: string
  text?: ReactNode | string
  fieldsProps?: {
    name: FieldProps
    email: FieldProps
    subject: FieldProps
    message: FieldProps
  }
  mandatoryText: string
  backgroundColor?: string
  submitProps?: FormSubmitProps
  errorTexts?: {
    required: string
    email: string
  }
  initialValues?: any
  onSubmit?: (values: ContactBlockValues) => void
  submitErrors?: string[]
}

const ContactBlock: FC<ContactBlockProps> = (props) => {
  const {
    className,
    title,
    hash,
    text,
    fieldsProps,
    mandatoryText,
    backgroundColor = '#F1F3FF',
    errorTexts,
    submitProps,
    initialValues,
    onSubmit = (_v: ContactBlockValues) => null,
    submitErrors,
  } = props

  const fields = [
    {
      name: 'name',
      field: TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'email',
      field: TextField,
      validation: Yup.string().required(errorTexts?.required).email(errorTexts?.email),
      required: true,
    },
    {
      name: 'subject',
      field: Select,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
    },
    {
      name: 'message',
      field: TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
      multiline: true,
    },
  ]

  const validationSchema = Yup.object().shape(
    fields?.reduce((arr, field) => ({ ...arr, [field.name]: field.validation }), {})
  )

  return (
    <SC.Box className={className} backgroundColor={backgroundColor} id={hash}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty }) => (
            <SC.Form onSubmit={handleSubmit}>
              <SC.Fields>
                {fields?.map((field, index) => {
                  const { field: Field, validation, ...fieldConfig } = field
                  const fieldProps = (fieldsProps as any)?.[field?.name]
                  const error = (touched as any)?.[field?.name]
                    ? ((errors as any)?.[field?.name] as string)
                    : ''
                  const value = (values as any)?.[field?.name]

                  return fieldProps ? (
                    <Field
                      key={index}
                      {...fieldConfig}
                      {...fieldProps}
                      error={error}
                      value={value}
                      onChange={handleChange}
                      onBlur={() => handleBlur(field?.name)}
                    />
                  ) : null
                })}
              </SC.Fields>
              <SC.MandatoryText>{mandatoryText}</SC.MandatoryText>
              <FormSubmitErrors submitErrors={submitErrors} />
              <SC.SubmitButton {...submitProps} isSuccess={submitProps?.isSuccess && !dirty} />
            </SC.Form>
          )}
        </Formik>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default ContactBlock
