import React, { FC } from 'react'

import { LinkProps, TextLinkProps } from '../Link'

import * as SC from './styled'

export type BreadcrumbProps = {
  className?: string
  parents?: TextLinkProps[]
  label?: string
}

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const { className, parents, label } = props

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Breadcrumb>
          {parents?.map((item, i) => (
            <SC.BreadcrumbEntry key={i} {...item} />
          ))}
          <SC.BreadcrumbEntry text={label} />
        </SC.Breadcrumb>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default Breadcrumb
