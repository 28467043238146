import React, { FC, ReactNode, useEffect, useState } from 'react'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type QuestionProps = {
  hash?: string
  title: string
  text?: ReactNode | string
}

export type RubricFAQProps = {
  className?: string
  icon: Icons
  title?: string
  color?: string
  text?: ReactNode | string
  questions?: QuestionProps[]
}

const RubricFAQ: FC<RubricFAQProps> = (props) => {
  const { className, icon, title, text, questions, color } = props

  const [currentHash, setCurrentHash] = useState('')

  useEffect(() => {
    let mounted = true
    const onHashChange = () => {
      if (mounted) setCurrentHash(window.location.hash.toString()?.replace('#', ''))
      console.log('hash changed', window.location.hash.toString()?.replace('#', ''))
    }
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => {
      mounted = false
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [])

  return (
    <SC.Box className={className}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.RubricIcon icon={icon} color={color} />
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Questions>
          {questions?.map((question, index) => (
            <SC.Question
              key={index}
              {...question}
              isOpen={currentHash === question?.hash}
              onChange={(v) => setCurrentHash(v ? question?.hash ?? '' : '')}
            />
          ))}
        </SC.Questions>
      </SC.Wrapper>
    </SC.Box>
  )
}

export default RubricFAQ
